import { Attention, Box, Caption, Flex, Shield, Spacer, Truck } from '@components';
import variables from '@styles/export.module.scss';
import styles from './ValuePropBullet.module.scss';

const ValuePropBullet = ({ iconType, description }) => {
	const getIcon = (iconType: string) => {
		switch (iconType) {
			case 'truck':
				return <Truck />;
			case 'box':
				return <Box />;
			case 'attention':
				return <Attention width={20} height={20} fill={variables.gray5} label='Attention' />;
			case 'shield':
				return <Shield />;
			default:
				return null;
		}
	};

	return (
		<Flex column className={styles.container} align='center' justify='center'>
			{getIcon(iconType)}
			<Spacer size={'0.4rem'} />
			<Caption small style={{ textAlign: 'center' }}>
				{description}
			</Caption>
		</Flex>
	);
};

export default ValuePropBullet;
